import Head from 'next/head'
import Image from 'next/image'

{/* importing components */}
import Header from '../components/Header'
import Events from '../components/Events'
import BlogPosts from '../components/BlogPosts'
import ClanCTA from '../components/ClanCTA'
{/* finished importing components */}


export default function Home() {
  return (
    <>
      <Head>
        <title>Combat Warriors</title>
        <meta name="description" content="Welcome to the official Combat Warriors website! Read our blog, check out our wiki, and connect with other players!" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="theme-color" content="#FF8C00" />
        <meta property="og:title" content="Combat Warriors" />
        <meta property="og:description" content="Welcome to the official Combat Warriors website! Read our blog, check out our wiki, and connect with other players!" />
        <meta property="og:image" content="https://cdn.combatwarriors.gg/meta_banner.png" />
        <meta property="og:url" content="https://combatwarriors.gg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Combat Warriors" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      
      <main>
        <Header />
        <BlogPosts />
        <Events />
        <ClanCTA />
      </main>
    </>
  )
}
