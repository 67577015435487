import { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import { useRouter } from 'next/router'

const descriptionFormatter = (description) => {
    return description.length > 150 ? description.substring(0, 150) + "..." : description;
}

const formatURL = (url) => {
    if (!url) return "";
    url = url.replace(/https:\/\/combatwarriors.gg/g, "https://blog.combatwarriors.gg");
    return url;
}

const DateFormatter = ({ date }) => {
    const randId = Math.floor(Math.random() * 100000);
    return (
        <>
            <div className="tooltip" data-tip={dayjs(date).format('MMMM D, YYYY')}>
                <time className="text-gray-400" data-tooltip-target={randId}>
                    {dayjs(date).fromNow()}
                </time>
            </div>
        </>
    )
}

const Avatar = ({ author }) => {
    const [imgSrc, setSrc] = useState(formatURL(author?.profile_image))
    const [error, setError] = useState(false);

    useEffect(() => {
        setSrc(formatURL(author.profile_image) || 'https://cdn.discordapp.com/embed/avatars/0.png');
    }, [author.profile_image]);

    return (
        <img
            className="inline-block h-8 w-8 rounded-full ring-2 ring-white cursor-pointer"
            src={imgSrc}
            onError={() => {
                if (!error) {
                    setSrc('https://cdn.discordapp.com/embed/avatars/0.png');
                    setError(true);
                }
            }}
            alt=""
            // show name on hover
            title={author.name}
        />
    );
}

export default function Example() {
    const router = useRouter();

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/v1/blog")
            .then((res) => res.json())
            .then((data) => {
                if (!data.success) {
                    alert(`Failed to fetch posts!`)
                }
                else {
                    setPosts(data?.data.slice(0, 6));
                }
            });
    }, []);

    return (
      <div className="bg-[#141414] py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-100 text-center sm:text-4xl">Latest Posts</h2>
                <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    {posts.map((post) => (
                        <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer border-2 border-gray-800 rounded-lg hover:border-orange-500 hover:bg-white hover:bg-opacity-5 transition" onClick={() => router.push(`/blog/${post.slug}`)}>
                            <div className="flex-shrink-0">
                                <img className="h-48 w-full object-cover" src={formatURL(post.feature_image)} alt="" />
                            </div>
                            <div className="flex-1 p-6 flex flex-col justify-between">
                                <div className="flex items-center gap-x-2 text-xs">
                                    <DateFormatter date={post.published_at} />
                                    {post?.tags?.length > 0 && <span className="text-gray-800">•</span>}
                                    {post.tags.map((tag) => (
                                        <a
                                            className="relative z-10 rounded-full bg-red-200 px-3 py-1.5 font-medium text-gray-900 cursor-default"
                                        >
                                            {tag.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="flex-1">
                                    <a href={post.href} className="block mt-2">
                                        <p className="text-xl font-semibold text-gray-200">{post.title}</p>
                                        <p className="mt-3 text-sm text-gray-300">{descriptionFormatter(post.meta_description || post.excerpt)}</p>
                                    </a>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex-shrink-0">
                                        {post.authors.map((author) => (
                                            <Avatar author={author} />
                                        ))}
                                    </div>
                                    <div className="ml-3">
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.datetime}>{post.date}</time>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
      </div>
    )
}
  