import { ClockIcon, UserCircleIcon } from '@heroicons/react/24/solid'

const events = [
    {
        id: 1,
        name: "Game Night",
        date: "2023-04-05 19:00:00",
        hosts: [
            "Candy"
        ]
    },
    {
        id: 2,
        name: "Banner Event",
        date: "2023-04-07 12:00:00",
        hosts: [
            "big.bun"
        ]
    },
    {
        id: 2,
        name: "Random Event Thing",
        date: "2023-04-09 14:19:00",
        hosts: [
            "big.bun",
            "Candy"
        ]
    }
]

const formatDate = (date) => {
    const d = new Date(date)
    return d.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    })
}

export default function Events() {
    return;
    return (
        <div className="bg-gradient-to-tr from-red-300 to-yellow-200 flex justify-center items-center py-12 mb-0 ">
            <div>
                <h1 className="text-4xl font-bold text-black text-center mb-8">Upcoming Events</h1> 
                <div className="md:px-4 md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 space-y-4 md:space-y-0">
                    {events.map((event) => (
                        <div className="bg-gray-100 border-2 border-gray-700 px-6 pt-2 pb-2 rounded-xl shadow-lg w-full h-full min-w-[400px]">
                            <h1 className="mt-4 text-gray-900 text-2xl font-bold">{event.name}</h1>
                            <div className="my-4 text-gray-900">
                                <div className="flex space-x-1 items-center">
                                    <ClockIcon className="h-6 w-6 text-gray-900" />
                                    <p>Starts: {formatDate(event.date)}</p>
                                </div>
                                {event?.ends && (
                                    <div className="flex space-x-1 items-center mt-1">
                                        <ClockIcon className="h-6 w-6 text-gray-900" />
                                        <p>Ends: {formatDate(event.ends)}</p>
                                    </div>
                                )}
                                <div className="flex space-x-1 items-center mt-1">
                                    <UserCircleIcon className="h-6 w-6 text-gray-900" />
                                    <p>Host{event.hosts.length > 1 ? "s" : ""}: {event.hosts.join(", ")}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
  }
  